import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy Policy" keywords=""/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Privacy Policy</h1>
                </div>
                <p>I like to keep things simple:</p>
                <p>I intentionally do not use third-party analytics or ads in my apps, and collect no user data nor cookies of any sort. I'm committed to making sure that this remains the case.</p>
                <p>Your settings & data are stored securely on your own devices, and, where appropriate, you may choose to back-up or sync through iCloud.</p>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage
